<template>
  <div>
    <div class="body-profile">
      <section class="main-container pt-5 pb-5">
        <div class="container top-container">
          <div class="content">
            <div class="container flex">
              <div class="card-matches card-bg-grey-white">
                <div class="text-center">
                  <h1 class="logo">
                    <a href="/"
                      ><img
                        class="index-logo"
                        src="../assets/img/euro-logo.png"
                        alt=""
                    /></a>
                  </h1>
                </div>
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    <!-- <small id="emailHelp" class="form-text text-muted"
                      >We'll never share your email with anyone else.</small
                    > -->
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>
                  <!-- <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                    />
                    <label class="form-check-label" for="exampleCheck1"
                      >Check me out</label
                    >
                  </div> -->
                  <button type="submit" class="btn btn-primary">Sign-in</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    msg: String,
  },
};
</script>

<style scope>
.body-profile {
  background-color: #1c253c;
  min-height: 450px;
}
.table-upcoming {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  opacity: 0.8;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin: 10px;
  min-width: 450px;
  font-size: 14px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid white;
}

.table--4cols > div {
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
  list-style: none;
  padding: 10px;
}

.table--2cols > .column-cell {
  width: 50%;
}

.table--3cols > .column-cell {
  width: 33.33%;
}

.table--4cols > .column-cell {
  width: 25%;
}

.table--5cols > .column-cell {
  width: 20%;
}

.table--6cols > .column-cell {
  width: 16.6%;
}
/* Table col 4 */

.table--4cols > .column-cell-1 {
  width: 15%;
}

.table--4cols > .column-cell-2 {
  width: 30%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.table--4cols > .column-cell-3 {
  width: 40%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.table--4cols > .column-cell-4 {
  width: 15%;
}

.tab-selection {
  background-color: transparent;
  color: #fff;
  overflow: hidden;
}

.tab-selection ul {
  display: flex;
}

.tab-selection ul li {
  position: relative;
  padding: 25px;
  margin: 0 5px;
}

.tab-selection a {
  color: #fff;
  font-size: 15px;
  text-decoration: none !important;
}

.tab-selection ul li:hover {
  color: #edaa34;
}

.tab-selection ul li:hover > a {
  color: #edaa34;
}

.tab-selection ul li.active > a {
  color: #edaa34;
}

.tab-selection ul li.active > a::before {
  content: "";
  position: absolute;
  bottom: 5px;
  color: #edaa34;
  background: #edaa34;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  left: calc(50% - 8px);
}

.tab-selection ul li:hover::before {
  content: "";
  position: absolute;
  bottom: 5px;
  color: #edaa34;
  background: #edaa34;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  left: calc(50% - 8px);
}
</style>
